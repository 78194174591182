import type { SearchIndexItem } from '~~/server/types'
import { useNuxtApp, defineStore, ref, computed, brotliDecompress } from '#imports'

export const useSearchCatalogIndex = defineStore('searchCatalogIndex', () => {
  /**
   *
   */
  const index = ref<SearchIndexItem[]>()
  const currentTime = ref<number>(0)
  const REFRESH_TIME = 60 * 15 // 15 minutes

  function needsRefresh() {
    return Math.floor(Date.now() / 1000) >= currentTime.value + REFRESH_TIME
  }

  async function loadIndex() {
    if (index.value && !needsRefresh()) return
    // try / catch needed to avoid pre-rendering error in nuxt build on github actions
    try {
      // load search index data
      const { $trpc } = useNuxtApp()
      const { data } = await $trpc.cat_Search.getSearchIndex.useQuery()
      if (data.value) index.value = brotliDecompress(data.value) as SearchIndexItem[]
      if (!(index.value && index.value.length)) console.info('search index not found')
      // console.info('search index loaded')
    } catch (error: unknown) {
      console.info('search index failed to load')
    }
  }

  const isIndexLoaded = computed<boolean>(() => {
    return index.value ? true : false
  })

  async function refreshIndex() {
    if (needsRefresh()) {
      await loadIndex()
      currentTime.value = Math.floor(Date.now() / 1000)
    }
  }

  return {
    // store objects
    index,

    // store functions
    isIndexLoaded,
    loadIndex,
    refreshIndex,
  }
})
